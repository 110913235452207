<template>
  <div class="sign-in-wrapper">
    <div class="sales-cta">
      <a href="https://orbitalstack.com/" target="blank">
        <img class="sign-in-logo" src="~@/assets/svg/sales-card.svg" alt="Schedule a Demo">
      </a> 
      <div class="cta">
        <p class="big">Rapid insights to guide your design decisions now, saving you from costly mistakes later.</p>
        <div class="button-row"><a class="button" href="https://meetings.hubspot.com/chrissy-lemmex/demo-meeting-link?__hstc=57679477.15ff98b6cf027bfc24c411091436bad3.1707572445976.1742327462682.1742331295376.629&__hssc=57679477.4.1742331295376&__hsfp=1838488382&uuid=221c1b6a-10d4-410b-988c-9c6f357f16b7" target="_blank">Schedule a Demo</a></div>
      </div>
    </div>
    <div class="form-wrapper">
      <div class="sign-in-form">
        <main  v-if="!formSubmitted">
          <h6 style="color: rgb(0,84,136);">Access Your Orbital Stack Project</h6>
          <p class="pt-2 font-weight-bold" style="font-size:14px">Enter your email below and we'll send you a one-time password to access your project if a project has been shared with you</p>
          <hr class="my-3"/>
          <notification-banner v-if="emailError"
            theme='error'
            title='Something went wrong'
            message='Please try again or contact support@orbitalstack.com for assistance'
            :show-close-button="false" />
          <FormulateForm class='username closer' @submit='getPassword()'>
            <FormulateInput
              label='Email address'
              type='email'
              element-class="mt-0"
              validation='required|email'
              v-model='email' />
            <button class="w-100">Get Password</button>
          </FormulateForm>
        </main>
        <b-spinner v-else variant="primary" class="loading-spinner"></b-spinner>
      </div>
    </div>
    <div class="sign-in-footer">
      <p class="smaller">©2025 <a href="https://www.orbitalstack.com/">Orbital Stack</a>. All rights reserved. Unauthorized duplication is strictly prohibited.</p>
    </div>
  </div>
</template>

<script>
import authenticationApiService from '@/network/authentication';
import { NotificationBanner } from 'rwdi-widgets';

export default {
  name: 'OTPEmailPage',
  components: {
    NotificationBanner
  },
  data() {
    return {
      email: null,
      formSubmitted: false,
      emailError: false
    };
  },
  methods: {
    async getPassword() {
      this.formSubmitted = true;
      const payload = {
        project_share_id: this.$route.query.project_share_id,
        email: this.email
      };
      try {
        const data = await authenticationApiService.generateOneTimePassword(payload);
        this.$router.push({ name: 'OTPLoginPage', query: { otp_key: data.otp_key, psid: this.$route.query.project_share_id } });
      } catch(e) {
        this.formSubmitted = false;
        this.emailError = true;
      }
    }
  }
};
</script>

<style scoped>

*, * > * {
  box-sizing: border-box!important;
}

body, html {
  height: 100vh;
  }
.sign-in-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  padding: 0;
  margin: 0;
  background-color: var(--primary-navy);
}

.sales-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  padding: 1.5rem;
  background-color: var(--primary-navy);
}

.sales-cta {
  color: #fff;
}

.cta {
  display: none;
}

.sign-in-footer {
  color: #fff;
  padding: 1em;
  text-align: center;
}

.sign-in-footer a {
  color: #fff;
}

a.button {
  text-decoration: none;
}

.sales-cta a {
  color: #fff;
  margin: 0 auto;
}

.sign-in-logo {
  width: 100%;
  height: auto;
  max-width: 15.625rem;
  padding: 1em

}

.form-wrapper {
  margin: 0;
  flex-grow: 2;
}

.sign-in-form {
  background-color: #fff;
  padding: 2em;
  border-radius: 0.313rem;
  box-shadow: 0 0 0.313rem 0.188rem rgba(0, 0, 0, 0.1);
  max-width: 25rem;
  margin: 0 auto;
}

.sign-in-form .button-row {
  justify-content: space-between;
}

.sign-in-form a {
  align-self: center;
}

.sign-in-form h4 {
  font-size: 1.25em;
}

.sign-in-button {
  width: 100%;
}

.no-top-margin-all-descendants * {
	margin-bottom: 0.5em;
}
.no-top-margin-all-descendants .input-title{
  margin-bottom: 1.5em !important;
  margin-top: 0.2em !important;
}

.submit-button {
  margin-top: 2em;
}

.loading-spinner {
  display: block;
  margin: auto;
}

@media screen and (min-width: 52rem) {

  .sign-in-wrapper {
    flex-direction: row;
    background-color: var(--grey-200);
  }

  .cta {
    display: block;
    margin: auto 0;
  }

  .sales-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: auto;
    max-width: 25rem;
    height: 100%;
    text-align: center;
    padding: 1.5rem;
    background-color: var(--primary-navy);
  }

  .sign-in-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 25rem;
  }
}

</style>

<style>
.submit-button .formulate-input-wrapper .formulate-input-element--submit button{
  background-color: var(--primary-blue);
  width: 100%;
  text-align: center !important;
  margin: 0 auto;
  display: inline;
  border: 0.66px solid rgb(0,84,136);
}
.formulate-input .formulate-input-wrapper .formulate-input-element {
  max-width: none !important;
}
</style>